import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

const config = {
  apiKey: "AIzaSyCHxUPMVEuWbzAEhxlsdVvbUd6TYpWp3xg",
  authDomain: "etchells-raceday.firebaseapp.com",
  databaseURL: "https://etchells-raceday.firebaseio.com",
  projectId: "etchells-raceday",
  storageBucket: "etchells-raceday.appspot.com",
  messagingSenderId: "286539277073"
};
firebase.initializeApp(config);

// firebase utils
const db = firebase.firestore();
const auth = firebase.auth();
const currentUser = auth.currentUser;

// firebase collections
const usersCollection = db.collection("users");
const boatsCollection = db.collection("boats");
const crewCollection = db.collection("crew");
const eventsCollection = db.collection("events");

export { db, auth, currentUser, usersCollection, boatsCollection, crewCollection, eventsCollection };
