<template>
  <div class="login text-center">
    <form @submit.prevent="register" class="form-signin">
      <h1 class="h3 mb-3 font-weight-normal">Please Register</h1>
      <label for="inputEmail" class="sr-only">Email address</label>
      <input
        v-model="email"
        type="email"
        id="inputEmail"
        class="form-control"
        placeholder="Email address"
        required
        autofocus
      />
      <label for="inputPassword" class="sr-only">Password</label>
      <input
        v-model="password"
        type="password"
        id="inputPassword"
        class="form-control"
        placeholder="Password"
        required
      />

      <button class="btn btn-lg btn-primary btn-block" type="submit">
        Register
      </button>
      <p class="mt-5 mb-3 text-muted">© 2017-2018</p>
    </form>
  </div>
</template>

<script>
import { auth } from "@/firebaseConfig.js";

export default {
  name: "register",
  data: function () {
    return {
      email: "",
      password: "",
    };
  },
  methods: {
    register: function () {
      auth
        .createUserWithEmailAndPassword(this.email, this.password)
        .then((user) => {
          this.$store.commit("setCurrentUser", user);
          // fb.usersCollection
          //   .doc(user.uid)
          //   .set({
          //     email: this.email
          //   })
          //   .then(() => {
          //this.$store.dispatch("fetchUserProfile");
          this.$router.push("/");
          // })
          // .catch(err => {
          //   console.log(err);
          // });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
/* .login {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
} */

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>