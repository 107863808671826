<template>
  <BasicLayout>
    <div class="container">
      <div class="card">
        <div class="card-body p-3">
          <h4>Create Boat</h4>
          <form @submit.prevent="save_boat">
            <div class="form-group">
              <label for="bowno">Bow Number</label>
              <input
                v-model="boat.bowno"
                type="text"
                id="bowno"
                class="form-control"
                placeholder="Bow Number"
                required
              />
            </div>
            <div class="form-group">
              <label for="sailno">Sail Number</label>
              <input
                v-model="boat.sailno"
                type="text"
                id="sailno"
                class="form-control"
                placeholder="Sail Number"
                required
              />
            </div>
            <div class="form-group">
              <label for="boat">Boat Name</label>
              <input
                v-model="boat.name"
                type="text"
                id="boat"
                class="form-control"
                placeholder="Boat Name"
                required
              />
            </div>
            <div class="form-group">
              <label for="owner">Owner Name</label>
              <input
                v-model="boat.owner"
                type="text"
                id="owner"
                class="form-control"
                placeholder="Owner Name"
                required
              />
            </div>
            <div class="form-group">
              <label for="email">Email address</label>
              <input
                v-model="boat.email"
                type="text"
                id="email"
                class="form-control"
                placeholder="Email Address"
                required
              />
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </BasicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import BasicLayout from "@/components/BasicLayout";
// import { mapState } from "vuex";
export default {
  name: "BoatNew",
  data() {
    return {
      boat: {},
    };
  },
  components: {
    BasicLayout,
  },
  computed: {
    line_qty: function () {
      return 4;
    },
  },
  methods: {
    save_boat() {
      const data = this.boat;
      data.sailno = Number(data.sailno);
      data.default_status = true;
      data.status = true;
      data.updating_status = false;
      data.last_update = new Date();
      db.collection("boats").add(data);
      this.$router.push("/");
    },
  },
};
</script>
