<template>
  <div class="boats">
    <div class="container-fluid">
      <div class="row">
        <div class="col p-2">
          <h2>BOATS</h2>
        </div>
        <div class="col p-2 text-right">
          <h2>
            <span class="badge badge-danger">{{ out_count }}</span>
            <span class="badge badge-success">{{ in_count }}</span>
          </h2>
        </div>
      </div>
    </div>
    <div class="col-12 p-2">
      <div class="card">
        <div class="card-body mt-1 p-1">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th scope="col">Bow No</th>
                <th scope="col" class="d-none d-sm-table-cell">Sail No</th>
                <th scope="col">Name</th>
                <th scope="col" class="d-none d-sm-table-cell">Email</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody class="text-left">
              <tr v-for="boat in boats" :key="boat.id">
                <td>{{ boat.bowno }}</td>
                <td class="d-none d-sm-table-cell">{{ boat.sailno }}</td>
                <td>{{ boat.name }}</td>
                <td class="d-none d-sm-table-cell">
                  <a :href="`mailto:${boat.owner}<${boat.email}>`">{{
                    boat.owner
                  }}</a>
                </td>
                <td v-if="currentUser">
                  <span
                    @click="admin_change_status(boat)"
                    v-if="boat.status"
                    class="badge badge-success"
                    title="Status"
                    id="participant_status"
                    >IN</span
                  >
                  <span
                    @click="admin_change_status(boat)"
                    v-else
                    class="badge badge-danger"
                    title="Status"
                    id="participant_status"
                    >OUT</span
                  >
                </td>
                <td v-else>
                  <span
                    v-if="boat.status"
                    class="badge badge-success"
                    :title="boat.last_update | moment_long"
                    id="participant_status"
                    >IN</span
                  >
                  <span
                    v-else
                    class="badge badge-danger"
                    :title="boat.last_update | moment_long"
                    id="participant_status"
                    >OUT</span
                  >
                </td>
                <td v-if="currentUser">
                  <router-link
                    :to="{ name: 'Boat Details', params: { boat_id: boat.id } }"
                    class="btn btn-outline-secondary btn-sm mr-2"
                    title="Edit Boat"
                  >
                    <edit-icon size="1x" class="custom-class"></edit-icon>
                  </router-link>
                  <button
                    @click="deleteItem(boat.id)"
                    type="button"
                    title="Delete Boat"
                    class="btn btn-outline-secondary btn-sm"
                  >
                    <trash-icon size="1x" class="custom-class"></trash-icon>
                  </button>
                </td>
                <td v-else class="text-center">
                  <button
                    @click.prevent="change_status(boat)"
                    type="button"
                    title="Change Status"
                    class="btn btn-outline-secondary btn-sm"
                  >
                    <toggle-right-icon
                      size="1x"
                      class="custom-class"
                    ></toggle-right-icon>
                  </button>

                  <!-- <button
                    @click.prevent="need_crew(boat)"
                    type="button"
                    title="Need Crew?"
                    class="btn btn-outline-secondary btn-sm ml-1"
                  >
                    <user-icon size="0.9x" class="custom-class"></user-icon>
                  </button> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div v-if="currentUser" class="col p-2">
      <div class="card">
        <div class="card-body">
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="customFile"
              @change="upload"
            />
            <label class="custom-file-label" for="customFile"
              >Choose file</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebaseConfig";

import moment from "moment";
import Papa from "papaparse";
import { auth } from "@/firebaseConfig.js";
import { getters } from "@/store";
// import EventDate from "@/components/EventDate";
// import BasicLayout from "@/components/BasicLayout";
import { ToggleRightIcon, EditIcon, TrashIcon } from "vue-feather-icons";
// import { mapState } from "vuex";
// import nativeToast from "native-toast";

export default {
  name: "Boats",
  data() {
    return {
      boats: [],
      search: "",
      eventDate: [],
      editDate: false,
    };
  },
  components: { ToggleRightIcon, EditIcon, TrashIcon },
  firestore() {
    return {
      boats: db
        .collection("boats")
        .orderBy("status", "desc")
        .orderBy("sailno", "desc"),
    };
  },
  computed: {
    // ...mapState(["userProfile", "currentUser"]),
    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return getters.userProfile();
    },
    in_count: function () {
      return this.boats.filter((r) => r.status == true).length;
    },
    out_count: function () {
      return this.boats.filter((r) => r.status == false).length;
    },
  },
  methods: {
    upload(event) {
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        complete(results) {
          console.table(results.data);
          for (let i = 0; i < results.data.length; i++) {
            const data = results.data[i];
            data.sailno = Number(data.sailno);
            data.default_status = true;
            data.status = true;
            data.updating_status = false;
            data.last_update = new Date();
            db.collection("boats").add(data);
          }
        },
      });
    },
    deleteItem(id) {
      console.log(id);
      db.collection("boats").doc(id).delete();
    },
    admin_change_status(boat) {
      console.log(boat.id);
      db.collection("boats").doc(boat.id).update({
        status: !boat.status,
        last_update: new Date(),
      });
    },
    change_status(boat) {
      console.log(JSON.stringify(boat));
      db.collection("boats")
        .doc(boat.id)
        .update({ updating_status: true, last_update: new Date() });

      this.$toast.success("Request Received - Please Check Your Email!", {
        theme: "toasted-primary",
        position: "top-right",
        duration: 5000,
      });
    },
    need_crew(boat) {
      console.log(boat.id);
      db.collection("boats").doc(boat.id).update({ need_crew: true });

      this.$toast.success("Request Received - Please Check Your Email!", {
        theme: "toasted-primary",
        position: "top-right",
        duration: 5000,
      });
    },
    reset_status() {
      this.boats.forEach(function (b) {
        db.collection("boats").doc(b.id).update({ status: b.default_status });
      });
    },
  },

  filters: {
    moment_short: function (timestamp) {
      let date = new Date(timestamp.seconds * 1000);
      return moment.utc(date).local().format("ll");
    },
    moment_long: function (timestamp) {
      let date = new Date(timestamp.seconds * 1000);
      return moment.utc(date).local().format("LLLL");
    },
  },
};
</script>