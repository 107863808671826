<template>
  <header>
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <router-link to="/" class="navbar-brand">
        <img src="../assets/EtchellsLogo.png" width="auto" height="40" alt />
      </router-link>
      <a class="navbar-brand" href="#">R A C E D A Y</a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav mr-auto">
          <!-- <li class="nav-item">
        <router-link to="/Orders" class="nav-link">Orders</router-link> 
          </li>-->
        </ul>
        <ul class="navbar-nav ml-auto">
          <li v-if="currentUser" class="nav-link">{{ currentUser.email }}</li>
          <li v-if="!currentUser" class="nav-item">
            <router-link to="/Login" class="nav-link">
              <button type="button" class="btn btn-outline-secondary">
                Login
              </button>
            </router-link>
          </li>
          <li v-if="currentUser" class="nav-item">
            <button
              @click="logout"
              type="button"
              class="btn btn-outline-secondary"
            >
              Logout
            </button>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
import { auth } from "@/firebaseConfig.js";
import { getters, actions } from "@/store";
export default {
  name: "navbar",
  computed: {
    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return getters.userProfile();
    },
    // ...mapState(["userProfile", "currentUser"])
  },
  // data() {
  //   return {
  //     isLoggedIn: false,
  //     currentUser: false
  //   };
  // },
  // created() {
  //   if (firebase.auth().currentUser) {
  //     this.isLoggedIn = true;
  //     this.currentUser = firebase.auth().currentUser.email;
  //   }
  // },

  methods: {
    logout: function () {
      auth
        .signOut()
        .then(() => {
          actions.clearUser();
          this.$router.push("/login");
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

