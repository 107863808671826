<template>
  <BasicLayout>
    <div class="container">
      <div class="card">
        <div class="card-body p-3">
          <h4>{{ boat.name }} ({{ boat.sailno }})</h4>
          <form @submit.prevent="save_boat">
            <div class="form-group">
              <label for="bowno">Bow Number</label>
              <input
                v-model="boat.bowno"
                type="text"
                id="bowno"
                class="form-control"
                placeholder="Bow Number"
                required
              />
            </div>
            <div class="form-group">
              <label for="sailno">Sail Number</label>
              <input
                v-model="boat.sailno"
                type="text"
                id="sailno"
                class="form-control"
                placeholder="Sail Number"
                required
              />
            </div>
            <div class="form-group">
              <label for="boat">Boat Name</label>
              <input
                v-model="boat.name"
                type="text"
                id="boat"
                class="form-control"
                placeholder="Boat Name"
                required
              />
            </div>
            <div class="form-group">
              <label for="owner">Owner Name</label>
              <input
                v-model="boat.owner"
                type="text"
                id="owner"
                class="form-control"
                placeholder="Owner Name"
                required
              />
            </div>
            <div class="form-group">
              <label for="email">Email address</label>
              <input
                v-model="boat.email"
                type="text"
                id="email"
                class="form-control"
                placeholder="Email Address"
                required
              />
            </div>
            <div class="form-group">
              <label for="default_status">Default Status</label>

              <select
                v-model="boat.default_status"
                class="form-control form-control"
              >
                <option v-bind:value="true">in</option>
                <option v-bind:value="false">out</option>
              </select>
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </BasicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import BasicLayout from "@/components/BasicLayout";
// import { mapState } from "vuex";
export default {
  name: "BoatDetails",
  data() {
    return {
      boat: {},
    };
  },
  firestore() {
    return {
      boat: db.collection("boats").doc(this.$route.params.boat_id),
    };
  },
  components: {
    BasicLayout,
  },
  computed: {
    line_qty: function () {
      return 4;
    },
  },
  methods: {
    save_boat() {
      console.log(this.boat.id);
      db.collection("boats").doc(this.boat.id).set(this.boat);
      this.$router.push("/");
    },
  },
};
</script>
