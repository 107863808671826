import Vue from "vue";
import Router from "vue-router";
import Raceday from "@/components/Raceday";
// import Boats from "@/components/Boats";
import BoatDetails from "@/components/BoatDetails";
import BoatNew from "@/components/BoatNew";
import CrewNew from "@/components/CrewNew";
import Login from "@/components/Login";
import Register from "@/components/Register";
import { auth } from "@/firebaseConfig";

Vue.use(Router);

let router = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      name: "Raceday",
      component: Raceday
    },
    {
      path: "/boat/:boat_id",
      name: "Boat Details",
      component: BoatDetails,
      meta: {
        requiresAuth: true
      }
    },
    {
      path: "/register",
      name: "Register",
      component: Register,
      meta: {
        requiresGuest: true
      }
    },
    {
      path: "/boat-new",
      name: "New Boat",
      component: BoatNew,
      meta: {
        requiresGuest: true
      }
    },
    {
      path: "/crew-new",
      name: "New Crew",
      component: CrewNew,
      meta: {
        requiresGuest: true
      }
    },
    {
      path: "/login",
      name: "Login",
      component: Login
    }
  ]
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const currentUser = auth.currentUser

  if (requiresAuth && !currentUser) {
    next('/login')
  } else if (requiresAuth && currentUser) {
    next()
  } else {
    next()
  }
})


export default router;
