import Vue from 'vue';
import {
  db
} from "@/firebaseConfig";

export const state = Vue.observable({
  currentUser: {},
  userProfile: {}
});

export const getters = {
  currentUser: () => state.currentUser,
  userProfile: () => state.userProfile
}

export const mutations = {
  setCurrentUser: (val) => state.currentUser = val,
  setUserProfile: (val) => state.userProfile = val
}

export const actions = {
  fetchCurrentUserProfile(user) {
    db.collection("users").doc(user.uid).get()
      .then(res => {
        const user_profile = res.data()
        mutations.setUserProfile(user_profile);
      })
      .catch(err => {
        console.log(err);
      });
  },
  clearUser() {
    mutations.setUserProfile({});
    mutations.setCurrentUser({});
  }
}







// export const store = {
//   state: {
//     currentUser: {
//       email: "alex@alexwebster.com"
//     }
//   },
//   setUser(user) {
//     this.state.currentUser = user;
//   },
//   clearUser() {
//     this.state.currentUser = {};
//   }
// };
