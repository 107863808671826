<template>
  <div class="crew">
    <div class="container">
      <div class="row">
        <div class="col p-2">
          <h2>CREW - COMING SOON!</h2>
        </div>
        <div class="col p-2 text-right">
          <h2>
            <span class="badge badge-success">{{ in_count }}</span>
          </h2>
        </div>
      </div>
    </div>
    <div class="col p-2">
      <div class="card">
        <div class="card-body">
          <table class="table table-hover table-sm">
            <thead>
              <tr>
                <th scope="col">Name</th>
                <th scope="col" class="d-none d-sm-table-cell">Email</th>
                <th scope="col" class="d-none d-sm-table-cell">Mobile</th>
                <th scope="col">Status</th>
                <th scope="col"></th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody class="text-left">
              <tr v-for="crew in crews" :key="crew.id">
                <td>{{ crew.name }}</td>
                <td class="d-none d-sm-table-cell">
                  <a :href="`mailto:${crew.name}<${crew.email}>`">{{
                    crew.name
                  }}</a>
                </td>
                <td class="d-none d-sm-table-cell">
                  {{ crew.mobile }}
                </td>
                <td v-if="currentUser">
                  <span
                    @click="admin_change_status(crew)"
                    v-if="crew.status"
                    class="badge badge-success"
                    title="Status"
                    id="participant_status"
                    >IN</span
                  >
                  <span
                    @click="admin_change_status(crew)"
                    v-else
                    class="badge badge-danger"
                    title="Status"
                    id="participant_status"
                    >OUT</span
                  >
                </td>
                <td v-else>
                  <span
                    v-if="crew.status"
                    class="badge badge-success"
                    title="Status"
                    id="participant_status"
                    >AVAILABLE</span
                  >
                  <span
                    v-else
                    class="badge badge-danger"
                    title="Status"
                    id="participant_status"
                    >OUT</span
                  >
                </td>
                <td v-if="currentUser">
                  <router-link
                    :to="{ name: 'Crew Details', params: { crew_id: crew.id } }"
                  >
                    <span class="badge badge-light">
                      <i class="fas fa-edit"></i>
                    </span>
                  </router-link>
                  <a
                    href="#"
                    @click="deleteItem(crew.id)"
                    class="badge badge-light"
                  >
                    <i class="fas fa-trash"></i>
                  </a>
                </td>
                <td v-else>
                  <button
                    @click.prevent="change_status(crew)"
                    type="button"
                    title="Change Status"
                    class="btn btn-outline-secondary btn-sm"
                  >
                    <i class="fas fa-exchange-alt"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <div v-if="currentUser" class="col p-2">
      <div class="card">
        <div class="card-body">
          <div class="custom-file">
            <input
              type="file"
              class="custom-file-input"
              id="customFile"
              @change="upload"
            />
            <label class="custom-file-label" for="customFile"
              >Choose file</label
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { db } from "@/firebaseConfig";
import Papa from "papaparse";
import { auth } from "@/firebaseConfig.js";
import { getters } from "@/store";

export default {
  name: "Crew",
  data() {
    return {
      crews: [],
      search: "",
      eventDate: [],
      editDate: false,
    };
  },
  created() {
    const crew_id = this.$route.query && this.$route.query.id;
    const new_status = this.$route.query && this.$route.query.status;

    if (crew_id && new_status) {
      db.collections("boats")
        .doc(crew_id)
        .update({ status: new_status == "true" });
    }

    if (!this.eventDate.event) {
      this.eventDate.event = "...";
    }
  },
  firestore() {
    return {
      crews: db
        .collection("crew")
        .orderBy("status", "desc")
        .orderBy("name", "desc"),
      // eventDate: db.collection.doc("event"),
    };
  },
  computed: {
    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return getters.userProfile();
    },
    in_count: function () {
      return this.crews.filter((r) => r.status == true).length;
    },
    out_count: function () {
      return this.crews.filter((r) => r.status == false).length;
    },
  },
  methods: {
    upload(event) {
      Papa.parse(event.target.files[0], {
        header: true,
        skipEmptyLines: true,
        dynamicTyping: true,
        complete(results) {
          console.table(results.data);
          for (let i = 0; i < results.data.length; i++) {
            const data = results.data[i];
            data.default_status = false;
            data.status = false;
            data.updating_status = false;
            db.collections("crew").add(data);
          }
        },
      });
    },
    deleteItem(id) {
      console.log(id);
      db.collections("crew").doc(id).delete();
    },
    edit_date() {
      this.editDate = true;
    },
    admin_change_status(crew) {
      console.log(crew.id);
      db.collections("crew").doc(crew.id).update({ status: !crew.status });
    },
    change_status(crew) {
      console.log(crew.id);
      db.collections("crew").doc(crew.id).update({ updating_status: true });
    },
    save_date() {
      db.collections("events")
        .doc("event")
        .set({ event: this.eventDate.event });
      this.editDate = false;
    },
    reset_status() {
      this.crews.forEach(function (b) {
        db.collections("crew").doc(b.id).update({ status: b.default_status });
      });
    },
  },
};
</script>