<template>
  <div class="login text-center">
    <div class="form-signin">
      <div class="text-center p-3">
        <h2 class="text-light">R A C E D A Y</h2>
      </div>
      <div v-if="!reset" @submit.prevent="login">
        <form @submit.prevent="login">
          <h1 class="h6 mb-3 font-weight-normal text-light">Please Sign In</h1>
          <label for="inputEmail" class="sr-only">Email address</label>
          <input
            v-model="email"
            type="email"
            id="inputEmail"
            class="form-control"
            placeholder="Email address"
            required
            autofocus
          />
          <label for="inputPassword" class="sr-only">Password</label>
          <input
            v-model="password"
            type="password"
            id="inputPassword"
            class="form-control"
            placeholder="Password"
            required
          />
          <button class="btn btn-lg btn-light btn-block" type="submit">
            Sign in
          </button>

          <div class="p-3">
            <a href="#" @click="forgot">Forgot Password?</a>
          </div>
        </form>
        <!-- <button
          @click="signInWithGoogle"
          class="btn btn-lg btn-outline-secondary btn-block"
          type="submit"
        >Google Login</button>-->
      </div>
      <form v-else @submit.prevent="reset_password">
        <h1 class="h3 mb-3 font-weight-normal">Please Enter Login Email</h1>
        <label for="inputEmail" class="sr-only">Email address</label>

        <input
          v-model="email"
          type="email"
          id="inputEmail"
          class="form-control"
          style="margin-bottom: 10px"
          placeholder="Email address"
          required
          autofocus
        />

        <a href="#" @click="forgot">Back to Login</a>
      </form>
    </div>
  </div>
</template>

<script>
import { firebase, auth } from "@/firebaseConfig";
import { mutations, actions } from "@/store";
// import { mapActions } from "vuex";
//import BasicLayout from "@/components/BasicLayout";
export default {
  name: "login",
  data: function () {
    return {
      email: "",
      password: "",
      reset: false,
    };
  },

  methods: {
    login: function () {
      auth.signInWithEmailAndPassword(this.email, this.password).then(
        (user) => {
          console.log(user);
          console.log(auth.currentUser);
          mutations.setCurrentUser(auth.currentUser);
          actions.fetchCurrentUserProfile(auth.currentUser);
          // this.$store.commit("setCurrentUser", auth.currentUser);
          // this.$store.dispatch("fetchCurrentUserProfile", auth.currentUser);
          this.$router.push("/");
        },
        (err) => {
          alert(err);
          // this.$toasted.error(err.message, {
          //   theme: "toasted-primary",
          //   position: "top-right",
          //   duration: 5000,
          // });
        }
      );
    },
    // ...mapActions(["setCurrentUser", "fetchCurrentUserProfile"]),

    // login: async function() {
    //   auth
    //     .signInWithEmailAndPassword(this.email, this.password)
    //     .then(user => {
    //       mutations.setCurrentUser(auth.currentUser);
    //       actions.fetchCurrentUserProfile(auth.currentUser);
    //       console.log();
    //       this.$router.push("/");
    //     })
    //     .catch(err => {
    //       this.$toasted.error(err.message, {
    //         theme: "toasted-primary",
    //         position: "top-right",
    //         duration: 5000
    //       });
    //     });
    // },

    //   auth
    //     .signInWithEmailAndPassword(this.email, this.password)
    //     .then(result => {
    //       this.setUserProfile(result.user);
    //     })
    //     .then(this.$router.push("/"))
    //     .catch(err => {
    //       this.$toasted.error(err.message, {
    //         theme: "toasted-primary",
    //         position: "top-right",
    //         duration: 5000
    //       });
    //     });
    // },

    signInWithGoogle: function () {
      const provider = new firebase.auth.GoogleAuthProvider();
      auth
        .signInWithRedirect(provider)
        .then((result) => {
          this.setUserProfile(result.user);
        })
        .then(this.$router.push("/"))
        .catch((err) => {
          this.$toast.error(err.message, {
            theme: "toasted-primary",
            position: "top-right",
            duration: 5000,
          });
        });
    },

    // setUserProfile: async function() {
    //   await mutations.setCurrentUser(auth.currentUser);
    //   await actions.fetchCurrentUserProfile(auth.currentUser);
    // },

    forgot: function () {
      console.log(this.reset);
      this.reset = !this.reset;
    },
    reset_password: function () {
      auth.sendPasswordResetEmail(this.email).then(
        (user) => {
          console.log(user);
          this.reset = false;
          return user;
        },
        (err) => {
          this.$toasted.error(err.message, {
            theme: "toasted-primary",
            position: "top-right",
            duration: 5000,
          });
          return err;
        }
      );
    },
  },
};
</script>

<style scoped>
.login {
  color: #fff;
  height: 100vh;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  /* background-color: #051d39; */
  background: rgba(0, 0, 0, 0.45);

  /* .bg { */
  /* The image used */
  background-image: url("../assets/bg.jpg");
  background-blend-mode: darken;
  /* opacity: 0.1; */

  /* Full height */
  /* height: 100%; */

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;
}
.form-signin .checkbox {
  font-weight: 400;
}
.form-signin .form-control {
  position: relative;
  box-sizing: border-box;
  height: auto;
  padding: 10px;
  font-size: 16px;
}
.form-signin .form-control:focus {
  z-index: 2;
}
.form-signin input[type="email"] {
  margin-bottom: -1px;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
  margin-bottom: 10px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
</style>

