<template>
  <BasicLayout>
    <div class="container">
      <div class="card">
        <div class="card-body p-3">
          <h4>Create Crew Record</h4>
          <form @submit.prevent="save_crew">
            <div class="form-group">
              <label for="crew">Name</label>
              <input
                v-model="crew.name"
                type="text"
                id="crew"
                class="form-control"
                placeholder="Crew Name"
                required
              />
            </div>
            <div class="form-group">
              <label for="email">Email address</label>
              <input
                v-model="crew.email"
                type="text"
                id="email"
                class="form-control"
                placeholder="Email Address"
                required
              />
            </div>
            <div class="form-group">
              <label for="mobile">Mobile address</label>
              <input
                v-model="crew.mobile"
                type="text"
                id="mobile"
                class="form-control"
                placeholder="Mobile Number"
                required
              />
            </div>
            <button type="submit" class="btn btn-primary">Submit</button>
          </form>
        </div>
      </div>
    </div>
  </BasicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import BasicLayout from "@/components/BasicLayout";
// import { mapState } from "vuex";
export default {
  name: "crewNew",
  data() {
    return {
      crew: {},
    };
  },
  components: {
    BasicLayout,
  },
  computed: {
    line_qty: function () {
      return 4;
    },
  },
  methods: {
    save_crew() {
      const data = this.crew;
      data.default_status = true;
      data.status = true;
      data.updating_status = false;
      data.last_update = new Date();
      db.collection("crew").add(data);
      this.$router.push("/");
    },
  },
};
</script>
