<template>
  <BasicLayout>
    <div class="raceday">
      <div class="card m-1">
        <div class="card-body">
          <div v-if="currentUser && editDate">
            <form @submit.prevent="save_date" class="form-inline">
              <label class="sr-only" for="inlineFormInputName2">Name</label>
              <input
                v-model="eventDate.event"
                type="text"
                class="form-control mb-2 mr-sm-2 col-6"
                id="inlineFormInputName2"
                placeholder="Event"
              />
              <button type="submit" class="btn btn-secondary mb-2 mx-2">
                Save
              </button>
              <button
                type="button"
                @click.prevent="reset_status"
                class="btn btn-secondary mb-2 mx-2"
              >
                Reset
              </button>
            </form>
          </div>
          <div v-else>
            <h4 @dblclick="edit_date" class="float-left">
              {{ eventDate.event }}
            </h4>
          </div>
        </div>
      </div>

      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
          <a
            class="nav-link active"
            id="home-tab"
            data-toggle="tab"
            href="#boats"
            role="tab"
            aria-controls="boats"
            aria-selected="true"
            >Boats</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            id="profile-tab"
            data-toggle="tab"
            href="#crew"
            role="tab"
            aria-controls="crew"
            aria-selected="false"
            >Crew</a
          >
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div
          class="tab-pane fade show active"
          id="boats"
          role="tabpanel"
          aria-labelledby="boats-tab"
        >
          <Boats />
        </div>
        <div
          class="tab-pane fade"
          id="crew"
          role="tabpanel"
          aria-labelledby="crew-tab"
        >
          <Crew />
        </div>
      </div>
    </div>
  </BasicLayout>
</template>

<script>
import { db } from "@/firebaseConfig";
import BasicLayout from "@/components/BasicLayout";
import { auth } from "@/firebaseConfig.js";
import { getters } from "@/store";
// import EventDate from "@/components/EventDate";
import Boats from "@/components/Boats";
import Crew from "@/components/Crew";
// import { mapState } from "vuex";

export default {
  name: "Raceday",
  components: {
    BasicLayout,
    Boats,
    Crew,
  },
  data() {
    return {
      eventDate: [],
      editDate: false,
    };
  },

  created() {
    const type = this.$route.query && this.$route.query.type;
    const id = this.$route.query && this.$route.query.id;
    const new_status = this.$route.query && this.$route.query.status;

    if (type == "boat" && id && new_status) {
      db.collection("boats")
        .doc(id)
        .update({ status: new_status == "true" });
    }

    if (type == "crew" && id && new_status) {
      db.collection("crew")
        .doc(id)
        .update({ status: new_status == "true" });
    }

    if (!this.eventDate.event) {
      this.eventDate.event = "...";
    }
  },

  firestore() {
    return {
      eventDate: db.collection("events").doc("event"),
    };
  },
  computed: {
    currentUser() {
      return auth.currentUser;
    },
    userProfile() {
      return getters.userProfile();
    },
  },
  methods: {
    edit_date() {
      this.editDate = true;
    },
    save_date() {
      db.collection("events").doc("event").set({ event: this.eventDate.event });
      this.editDate = false;
    },
  },
};
</script>

<style scoped>
</style>
