<template>
  <div class="basic-layout">
    <Navbar />
    <div class="container-lg">
      <slot />
    </div>
    <Footer />
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import Footer from "@/components/Footer";

export default {
  name: "BasicLayout",
  components: {
    Navbar,
    Footer,
  },
};
</script>

<style scoped>
.basic-layout {
  height: 100%;
}

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px;
  line-height: 60px; /* Vertically center the text there */
  background-color: #343a40;
}

.basic-layout > .container {
  padding: 10px 15px 0;
}

.footer > .container {
  padding-right: 15px;
  padding-left: 15px;
}

code {
  font-size: 80%;
}
</style>
