import Vue from "vue";
import App from "./App.vue";
import router from "./routes";
import { actions } from "@/store";

import { auth } from "@/firebaseConfig";

// import { store } from "./store.js";
import { firestorePlugin } from 'vuefire'

// import { fb, auth } from "@/firebaseConfig"

import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'vue-select/dist/vue-select.css';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import "./assets/style.css";

const toast_options = {
  transition: "Vue-Toastification__bounce",
  maxToasts: 10,
  newestOnTop: true,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: "button",
  icon: true,
  rtl: false
};

Vue.use(firestorePlugin)
Vue.use(Toast, toast_options);
Vue.config.productionTip = false;

let app;

auth.onAuthStateChanged(user => {

  if (user) {
    actions.fetchCurrentUserProfile(user);
  }
  if (!app) {
    app = new Vue({
      router,
      render: h => h(App)
    }).$mount("#app");
  }
});



// auth.onAuthStateChanged(user => {
//   if (user) {
//     actions.fetchCurrentUserProfile(user);
//   }
//   if (!app) {
//     app = new Vue({
//       render: h => h(App),
//       router,
//       store
//     }).$mount("#app");
//   }
// });

// auth.onAuthStateChanged(user => {
//   if (user) {
//     store.commit("setCurrentUser", user);
//     store.dispatch("fetchUserProfile");

//     fb.usersCollection.doc(user.uid).onSnapshot(doc => {
//       store.commit('setUserProfile', doc.data())
//     })
//   }
// });
